import React, { useContext, useEffect } from 'react';
// import { Switch, Route } from 'react-router-dom';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import WebContext from './Contexts/WebContext';
import { ToastContainer, Slide } from 'react-toastify';
// import PrivateRoute from './components/PrivateRoute';
// import Login from './Modules/FrontEnd/Auth/Login';
import asset_loader from './assets/images/loader.svg';
import NotifierService from './_services/NotifierService';

const Login = React.lazy(() => import('./Modules/FrontEnd/Auth/Login'));
const FrontEnd = React.lazy(() => import('./Modules/FrontEnd'));
const Admin = React.lazy(() => import('./Modules/Admin'));
const Manager = React.lazy(() => import('./Modules/Manager'));

function App() {
    //   const authCtx = useContext(AuthContext)
    const webCtx = useContext(WebContext);
    const navigate = useNavigate();

    useEffect(() => {
        initializeTheme();

        axios.interceptors.response.use(
            function (successRes) {
                return successRes;
            },
            function (error) {
                if (!error.response.config.url.includes('login') && (error.response.status === 401 || error.response.statusText === 'Unauthorized')) {
                    webCtx.changeLoginStatus(false);
                    localStorage.removeItem('auth_token');
                    navigate('/login');
                    NotifierService.notify('warning', "Session timeout, Try to login again");
                }
                return Promise.reject(error);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeTheme = () => {
        document.body.classList.add(webCtx.appTheme);
    };

    const isWaiting = () => {
        return (
            <div className="lazy-loader">
                <img src={asset_loader} alt="Loading" />
            </div>
        );
    };

    return (
        // <React.Suspense fallback={isWaiting()}>
        //     <Switch>
        //         {!webCtx.isLoggedIn && (
        //             <Route exact path={!webCtx.isLoggedIn ? '/login' : ''} component={Login} />
        //         )}
        //         <PrivateRoute component={Admin} path="/admin" />
        //         <Route path="/" component={FrontEnd} />
        //     </Switch>
        //     <ToastContainer transition={Slide} />
        // </React.Suspense>

        <div>
            <React.Suspense fallback={isWaiting()}>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    {/* <Route path='/register' element={<Auth />} /> */}
                    {/* <Route path='/account-verification/:verification_token' element={<Auth />} /> */}
                    {/* <Route path='/forgot-password' element={<Auth />} /> */}
                    <Route path='/*' element={<FrontEnd />} />
                    <Route
                        path="/admin/*"
                        element={
                            <RequireAuth checkAdmin={true}>
                                <Admin />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/representative/*"
                        element={
                            <RequireAuth checkManager={true}>
                                <Manager />
                            </RequireAuth>
                        }
                    />
                    {/* <Route path='/*' element={<Home/>} /> */}
                </Routes>
                <ToastContainer transition={Slide} />
            </React.Suspense>
        </div>
    );
}

function RequireAuth({ children, checkAdmin, checkManager }) {
    const webCtx = useContext(WebContext);
    let location = useLocation();

    if (!webCtx.isLoggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (checkAdmin && !webCtx.isAdmin()) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (checkManager && !webCtx.isManager()) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}

export default App;
