import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import { WebContextProvider } from './Contexts/WebContext';

import './Modules/FrontEnd/Shared/i18n';
import './assets/styles/index.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

ReactDOM.render(
    <React.StrictMode>
        <WebContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </WebContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// React's StrictMode is sort of a helper component that will help you write better react components, you can wrap a set of components with <StrictMode /> and it'll basically:
// Verify that the components inside are following some of the recommended practices and warn you if not in the console.
// Verify the deprecated methods are not being used, and if they're used strict mode will warn you in the console.
// Help you prevent some side effects by identifying potential risks.
// As the documentation says, strict mode is development oriented so you don't need to worry about it impacting on your production build.
// I've found it especially useful to implement strict mode when I'm working on new code bases and I want to see what kind of code/components I'm facing. Also if you're on bug hunting mode, sometimes it's a good idea to wrap with <StrictMode /> the components/blocks of code you think might be the source of the problem.
