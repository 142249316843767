const i18n = {
    brand_title : 'Imparo-Technic',
    menu_training : 'Trainings',
    menu_partners : 'Partners',
    menu_jobs : 'Job',
    menu_contact : 'Contact',
    menu_online_library : 'Online Library',
    menu_video_lessons : 'Video Lessons',
    menu_in_class_training : 'In-Class Training',
    menu_faq : 'FAQ',
    menu_about_us : 'About Us',
    menu_our_team : 'Our Team',
    menu_contact_us : 'Contact Us',
    menu_courses : 'Courses',
    menu_hello: "Hi",


    navigate_to : 'Navigate to',

    hero_title : 'Technical Staff Training',
    hero_subtitle : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged',

    courses:'Courses',
    trainings:'Trainings',
    explore_popular_trainings: 'Explore Popular Trainings',
    explore_courses_subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
    course_title: '1st category vehicle inspection\'s point',
    course_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
    view_all_trainings: 'View All Trainings',
    request_enrollment_btn: 'Request Enrollment',
    enrol_user: 'Enrol user',

    reviews: 'Reviews',
    reviews_sub: 'See what other people have to say about us',
    reviewer_name: 'Azhar M',
    reviewer_designation: 'Software Engineer',
    reviewer_review: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text',

    subscribe_to_newsletter: 'Subscribe to our Newsletter',
    subscribe: 'Subscribe',
    enter_your_email: 'Enter Your Email Address',

    community: 'Community',
    usefull_links: 'Useful Links',
    recent_trainings : 'Recent Trainings',
    designed_by : 'Designed & Developed by',

    mechanics : 'Mechanics',
    training_title : '1st category vehicle inspection\'s point',
    training_subtitle : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
    vehicle_inspection : 'Vehicle Inspection',
    
    about : 'About',
    about_course : 'About the Course',
    about_course_sub_heading : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    course_objective : 'Courses Objective',
    course_objective_sub_heading : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply.',
    course_content : 'Course Content',
    location : 'Location',
    tomislav_ivanchev : 'Tomislav Ivanchev',
    regional_representative : 'Regional Representaive',
    training_fee : 'Training Fee',
    passing_score : 'Passing score',
    region : 'Region',
    course_region: 'Region',
    training_place: 'Training place',
    germany : 'Germany',
    exam_question_limit : 'Exam Questions Limit',
    available : 'Available',
    examination_date : 'Examination Date',
    examination_place : 'Examination Place',
    berlin : 'Berlin',
    reviews_ratings : 'Reviews and Ratings',
    full_name : 'Full Name',
    designation : 'Designation',
    professional_trainer : 'Professional Trainer',
    qualification : 'Qualification',
    experience : 'Experience',
    years : '10 Years',

    instruction_fill_out_form : 'Fill out the form carefully for registration',
    student_name : 'Student Name',
    first_name : 'First Name',
    required_field: 'This field is required',
    first_name_required : 'First name field is required',
    last_name : 'Last Name',
    last_name_required : 'Last name field is required',
    password : 'Password',
    password_required : 'Password field is required',
    confirm_password : 'Confirm Password',
    confirm_password_required : 'Confirm Password field is required',
    address : 'Address',
    street_address : 'Street Address',
    street_address_2 : 'Street Address Line 2',
    city : 'City',
    state_province : 'State / Province',
    postal_zipcode : 'Postal / Zipcode',
    provide_address: 'Please provide us the following information in order to issue your invoice',
    student_email : 'Student E-mail',
    company_invoice: 'Company Invoice',
    company_name: 'Company Name',
    company_address: 'Company Address',
    register_number: 'Register Number',
    vat_number: 'VAT Number',
    company_invoice_instruction: 'If you would like an invoice to be issued to a company, please fill in the following data',
    verify_code : 'Enter verification code',
    code_instruction: 'We have already sent a verification code to your email, copy that code from the email and enter here',
    instruction_verify_code : 'Please type the verification code',
    select_city: 'Select City',
    email_required : 'Email field is required',
    contact_number : 'Contact Number',
    description : 'Description',
    description_here : 'Here can be your short description',
    next: 'Next',
    back : 'Back',
    submit: 'Submit',

    enrolment_form : 'Enrolment Form',
    basic_info: 'Basic Info',
    verify_email: 'Verify Email',
    payment_procedure: 'Payment Procedure',
    thank_you : 'Thank You',
    you_are_done : 'You\'re all set. Your Enrolment is submitted successfully. It is in the waiting list and You\'ll receive confirmation shortly.',
    pay_fee : 'Pay your training fee',
    instruction_account_information : 'Following are the account information',
    instruction_transaction_id : 'Enter Transaction ID',
    transaction_id : 'Transaction ID',
    bank_name : 'Bank Name',
    national_bank : 'National Bank of Germany',
    account_title : 'Account Title',
    already_paid : 'Already paid',
    already_paid_instruction : 'If you are done with payment, Ask support to manually validate your transaction',
    notes: 'Notes',
    help_support_validate_transaction: 'How can you help support to validate your payment?',
    imparo_technic : 'Imparo Technic',
    account_no : 'Account No',
    or: 'or',
    instruction_payment_proof : 'Upload your payment proof',
    back_to_training: 'Back to Trainings',
    
    partners : 'Partners',
    partners_shown : 'partners shown',
    april_13 : 'April 13',
    total_projects : 'Total Projects',
    view_more_images : 'view more images',

    market_place : 'Marketplace',
    jobs_shown : 'jobs shown',
    jobs_desc : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard text Lorem Ipsum is simply',
    public_information : 'Public Inofrmation',
    instruction_everyone : 'Everyone on Imparo-Technic can view these details',
    job_type : 'Job type',
    add_to_favorites : 'Add to favorites',
    hide_job : 'Hide this job',
    annual_profit : 'Annual Profit',
    annual_revenue : 'Annual Revenue',
    number_customers : 'Number of customers',
    date_founded : 'Date Founded',
    may : 'May 2021',
    startup_team : 'Startup team',
    asking_price : 'Asking price',
    load_more : 'Load more',
    premium : 'Premium',

    our_address : 'Our Address',
    email_us : 'Email Us',
    call_us : 'Call Us',
    your_name : 'Your Name',
    your_email : 'Your Email',
    subject : 'Subject',
    your_message : 'Your Message',
    send_message : 'Send Message',

    discard_changes: 'Discard Changes',
    sure_to_discard: 'Are you sure you want to discard these changes?',
    confirm: 'Confirm',
    ignore: 'Ignore',

    technical_staff_training : 'Technical staff training',
    welcome_back : 'Welcome Back',
    login_account : 'Login Account',
    email_id : 'Email ID',
    forget_password : 'Forget Password?',
    login : 'Login',

    admin_panel: 'Admin Panel',
    dashboard: 'Dashboard',
    logout : 'Logout',
    logout_confirmation : 'Are you sure you want to Logout',
    stay_logged_in : 'Stay Logged In',

    forgot_password : 'Forgot Password',
    reset_password_instruction : 'Password reset instructions will be sent to this email address',
    reset : 'Reset',
    cancel : 'Cancel',

}

export default i18n;