const i18n = {
    brand_title : 'Imparo-Technic',
    menu_training : 'Обучения',
    menu_partners : 'Партньори',
    menu_jobs : 'Работни места',
    menu_contact : 'Контакт',
    menu_online_library : 'Онлайн библиотека',
    menu_video_lessons : 'Видео уроци',
    menu_in_class_training : 'Обучение в клас',
    menu_faq : 'ЧЗВ',
    menu_about_us : 'За нас',
    menu_our_team : 'Нашия екип',
    menu_contact_us : 'Свържете се с нас',
    menu_hello: "Хей",

    navigate_to : 'Навигирайте до',

    hero_title : 'Обучение на технически персонал',
    hero_subtitle : 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е стандартният в индустрията фиктивен текст още от 1500-те години, когато неизвестен принтер взел камбуз от шрифт и го разбъркал, за да направи книга с образци. Той е оцелял не само пет века, но и скока в електронния набор, оставайки по същество непроменен',

    courses:'Курсове',
    trainings:'Обучения',
    explore_popular_trainings: 'Разгледайте популярни Обучения',
    explore_courses_subtitle: 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е индустрията.',
    course_title: 'Пункт за проверка на превозни средства 1-ва категория',
    course_desc: 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е индустрията.',
    view_all_trainings: 'Вижте всички обучения',
    request_enrollment_btn: 'Заявка за записване',
    enrol_user: 'Enrol user',

    reviews: 'Отзиви',
    reviews_sub: 'Вижте какво други хора имат да кажат за нас',
    reviewer_name: 'Азхар М',
    reviewer_designation: 'Софтуерен инженер',
    reviewer_review: 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията',

    subscribe_to_newsletter: 'Абонирайте се за нашия бюлетин',
    subscribe: 'Абонирай се',
    enter_your_email: 'Напишете своя емайл адрес',

    community: 'Общност',
    usefull_links: 'Полезни връзки',
    recent_trainings : 'Последни обучения',
    designed_by : 'Проектиран и разработен от',

    courses_cmp_heading : 'Курсове',
    mechanics : 'механика',
    training_title : 'Пункт за проверка на превозни средства 1-ва категория',
    training_subtitle : 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е индустрията.',
    vehicle_inspection : 'Инспекция на превозното средство',
   
    courses_cmp_course_details_tab_about : 'относно',
    about_course : 'Относно курса',
    about_course_sub_heading : 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията',
    course_objective : 'Цел на курса',
    course_objective_sub_heading : 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия.',
    course_content : 'Съдържание на учебната дисциплина',
    location : 'Местоположение',
    tomislav_ivanchev : 'Томислав Иванчев',
    regional_representative : 'Регионален представител',
    training_fee: 'Такса за обучение',
    passing_score : 'Проходен резултат',
    region : 'Регион/Град',
    training_place: 'Място на провеждане на обучението',
    germany : 'Германия',
    exam_question_limit : 'Лимит на изпитните въпроси',
    available : 'На разположение',
    examination_date : 'Дата на изпита',
    examination_place : 'Място на изпита',
    berlin : 'Берлин',
    reviews_ratings : 'Отзиви и оценки',
    full_name : 'Пълно име',
    designation : 'Обозначаване',
    professional_trainer : 'Професионален треньор',
    qualification : 'Квалификация',
    experience : 'Опит',
    years : '10 години',

    instruction_fill_out_form : 'Попълнете внимателно формуляра за регистрация',
    student_name : 'Име на кандидата',
    first_name : 'Първо име',
    required_field: 'Това поле е задължително',
    first_name_required : 'Полето за собствено име е задължително',
    last_name : 'Фамилия',
    last_name_required : 'Полето за фамилия е задължително',
    password : 'парола',
    password_required : 'Полето за парола е задължително',
    confirm_password : 'потвърди парола',
    confirm_password_required : 'Полето за потвърждение на паролата е задължително',
    address : 'Адрес',
    street_address : 'Адрес',
    street_address_2 : 'Адрес 2',
    city : 'Град',
    state_province : 'Щат/Провинция',
    postal_zipcode : 'Пощенски/пощенски код',
    provide_address: 'Моля, предоставете ни следната информация, за да издадем Вашата фактура',
    student_email : 'Имейл на кандидата',
    company_invoice: 'Фирмена фактура',
    company_name: 'Име на фирма',
    company_address: 'Адрес',
    register_number: 'ЕИК номер',
    vat_number: 'Номер по ДД',
    company_invoice_instruction: 'Ако желаете да бъде издадена фактура на фирма, моля попълнете следните данни',
    select_city: 'Изберете Град',
    email_required : 'Полето за имейл е задължително',
    verify_code : 'Въведете код за верификация',
    code_instruction: 'Вече сме изпратили код за потвърждение на вашия имейл, копирайте кода от имейла и въведете тук',
    instruction_verify_code : 'Моля, въведете кода за потвърждение',
    contact_number : 'Номер за връзка',
    description : 'Описание',
    description_here : 'Тук може да бъде вашето кратко описание',
    next: 'Следващия',
    back : 'обратно',
    submit: 'Изпращане',

    enrolment_form : 'Формуляр за записване',
    basic_info: 'Основна информация',
    verify_email: 'Потвърди Имейл',
    payment_procedure: 'Процедура за плащане',
    thank_you : 'Благодаря ти',
    you_are_done : 'Готов сте. Вашата регистрация е изпратена успешно. Той е в списъка на чакащите и скоро ще получите информация.',
    pay_fee : 'Платете таксата си за обучение',
    instruction_account_information : 'Данните за банковия превод',
    instruction_transaction_id : 'Въведете ID на транзакция',
    transaction_id : 'номер на транзакцията',
    bank_name : 'Име на банката',
    national_bank : 'Национална банка на Германия',
    account_title : 'Титуляр',
    already_paid : 'Вече платено',
    already_paid_instruction : 'Ако сте готови с плащането, помолете поддръжката да потвърди ръчно вашата транзакция',
    notes: 'Бележки',
    help_support_validate_transaction: 'Как можете да помогнете на поддръжката да потвърди плащането ви?',
    imparo_technic : 'Импаро-Техник',
    account_no : 'Сметка №',
    or: 'или',
    instruction_payment_proof : 'Качете своя профил за плащане',
    back_to_training: 'Обратно към Обучения',

    partners : 'Партньори',
    partners_shown : 'показани партньори',
    date_founded : 'Дата на основаване',
    april_13 : '13 април',
    total_projects : 'Общо проекти',
    view_more_images : 'вижте още изображения',

    market_place : 'Пазар',
    jobs_shown : 'показани работни места',
    jobs_desc : 'Lorem Ipsum е просто фалшив текст от печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията Lorem Ipsum е просто фиктивен текст за печатарската и наборната индустрия. Lorem Ipsum е стандартният текст в индустрията. Lorem Ipsum е просто',
    public_information : 'Обществена информация',
    instruction_everyone : 'Всеки в Imparo-Technic може да види тези подробности',
    job_type : 'Вид работа',
    add_to_favorites : 'Добави към любими',
    hide_job : 'Скрийте тази работа',
    annual_profit : 'Годишна печалба',
    annual_revenue : 'Годишен приход',
    number_customers : 'Брой клиенти',
    may : 'май 2021',
    startup_team : 'Стартъп екип',
    asking_price : 'Искана цена',
    load_more : 'Зареди още',
    premium : 'Премиум',

    our_address : 'Нашият адрес',
    email_us : 'Пишете ни',
    call_us : 'Обадете ни се',
    your_name : 'Твоето име',
    your_email : 'Твоят имейл',
    subject : 'Предмет',
    your_message : 'Твоето съобщение',
    send_message : 'Изпрати съобщение',

    discard_changes: 'Отхвърлите промените',
    sure_to_discard: 'Сигурни ли сте, че искате да отхвърлите тези промени?',
    confirm: 'Потвърдете',
    ignore: 'Игнорирайте',

    technical_staff_training : 'Обучение на техническия персонал',
    welcome_back : 'Добре дошъл обратно',
    login_account : 'Вход в акаунт',
    email_id : 'Имейл ID',
    forget_password : 'Забравена парола?',
    login : 'Влизам',

    admin_panel: 'Админ панел',
    dashboard: 'Табло',
    logout : 'Излез от профила си',
    logout_confirmation : 'Сигурни ли сте, че искате да излезете',
    stay_logged_in : 'Останете в профила си',

    forgot_password : 'Забравена парола',
    reset_password_instruction : 'На този имейл адрес ще бъдат изпратени инструкции за нулиране на паролата',
    reset : 'Нулиране',
    cancel : 'Отмяна',
}

export default i18n;