class GlobalService {

    httpOptions = {}; // for HTTP Headers

    // baseUrl = 'http://localhost:8000';
    baseUrl = 'https://it-be.mytextbook.net';
    // baseUrl = process.env.REACT_APP_API_BASE_URL;
    // showContent = [process.env.REACT_APP_QA_ENV, process.env.REACT_APP_LOCAL_ENV].includes(window.location.hostname);

    // // -----------Auth---------
    login = `${this.baseUrl}/api/login`;
    // register = `${this.baseUrl}/api/v1/auth/register`;
    // login = `${this.baseUrl}/api/v1/auth/login`;
    // verifyEmail = `${this.baseUrl}/api/v1/auth/verify-email`;
    // forgotPassword = `${this.baseUrl}/api/v1/auth/forgot-password`;
    // resetPassword = `${this.baseUrl}/api/v1/auth/reset-password`;
    
    // // -----------Dashboard---------
    // dashboard = `${this.baseUrl}/api/dashboard`;

    // // -----------Projects---------
    // projects = `${this.baseUrl}/api/v1/projects`;
    // newProject = `${this.baseUrl}/api/v1/projects/new`;
    // saveProject = `${this.baseUrl}/api/v1/projects/save`;
        
}

export default new GlobalService();
