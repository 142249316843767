import React, { useState } from "react";
import Globals from "../_services/GlobalService";
// import GlobalService from '../Modules/Admin/_servicesAdmin/global.service';
// import GlobalService from '../_services/GlobalService';

const WebContext = React.createContext({
    appTheme: '',
    switchTheme: () => { },

    appLang: '',
    switchLang: () => { },

    isLoggedIn: false,
    changeLoginStatus: () => { },
    isAdmin: () => {},
    isManager: () => {},

    loginUserName: '',
    setloginUserName: () => {},

    logout:  () => { },
});

export const WebContextProvider = (props) => {
    const initialTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark-theme';
    const [theme, setTheme] = useState(initialTheme);

    const initialLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    const [language, setLanguage] = useState(initialLang);

    const siteUrls = {
        login: Globals.login,
    }

    // check isValid() from service
    const isValid = () => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            Globals.httpOptions = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };
            const payload = token.split('.')[1];
            // const payloadParts = JSON.parse(atob(payload));
            const payloadParts = JSON.parse(Buffer.from(payload, 'base64'));
            console.log(payloadParts);
            if (payloadParts) {
                return Object.values(siteUrls).indexOf(payloadParts.iss) > -1 ? true : false;
            }
            // return true;
        } else {
            Globals.httpOptions = {};
            return false;
        }

        // setIsUserLogin(st);
        // localStorage.setItem('theme', st);
    };

    const isUserAdmin = () => {
        const token = localStorage.getItem('auth_token');
        const payload = token.split('.')[1];
        const payloadParts = JSON.parse(Buffer.from(payload, 'base64'));
        // console.log('PAYLOAD-PARTS', payloadParts);
        return payloadParts.access_type === 'admin';
    }

    const isUserManager = () => {
        const token = localStorage.getItem('auth_token');
        const payload = token.split('.')[1];
        const payloadParts = JSON.parse(Buffer.from(payload, 'base64'));
        // console.log('PAYLOAD-PARTS', payloadParts);
        return payloadParts.access_type === 'manager';
    }

    const initialUserName =  localStorage.getItem('username');
    const [isUserLogin, setIsUserLogin] = useState(isValid);
    const [userName, setUserName] = useState(initialUserName);
    
    const changeAuthStatus = (st) => {
        console.log('CHANGE-State', st);
        setIsUserLogin(isValid);
    };

    const handleLogout = () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        setIsUserLogin(isValid);
    }

    const themeHandler = (th) => {
        setTheme(th);
        localStorage.setItem('theme', th);
    };

    const languageHandler = (lng) => {
        setLanguage(lng);
        localStorage.setItem('language', lng);
    };

    const contextValue = {
        appTheme: theme,
        switchTheme: themeHandler,

        appLang: language,
        switchLang: languageHandler,

        isLoggedIn: isUserLogin,
        changeLoginStatus: changeAuthStatus,
        isAdmin: isUserAdmin,
        isManager: isUserManager,

        loginUserName: userName,
        setloginUserName: setUserName,
    
        logout: handleLogout
    }
    return <WebContext.Provider value={contextValue}>{props.children}</WebContext.Provider>
};

export default WebContext;