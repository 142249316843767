import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";

// Importing translation files
import translationEN from "../../../assets/lang/en.js";
import translationBG from "../../../assets/lang/bg.js";

let initialLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  bg: {
    translation: translationBG,
  },
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

//i18N Initialization
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    // lng:"en", //default language
    // lng:setLang, //default language
    lng: initialLang, //default language
    fallbackLng: 'en',
    supportedLngs: ['en', 'bg'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;